import React from "react";

import Dashboard from "./Dashboard";
import { ContactsProvider } from "../contexts/ContactsProvider";
import { ConversationsProvider } from "../contexts/ConversationsProvider";

function App({ id, conversationId, konsultasiId, statusProses }) {
  const dashboard = (
    <ContactsProvider>
      <ConversationsProvider
        id={id}
        convId={conversationId}
        konsultasiId={konsultasiId}
        statusProses={statusProses}
      >
        <Dashboard
          id={id}
          konsultasiId={konsultasiId}
          statusProses={statusProses}
        />
      </ConversationsProvider>
    </ContactsProvider>
  );

  return id ? dashboard : <div />;
}

export default App;

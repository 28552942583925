import React, { useState, useCallback, useEffect } from "react";

import { useConversations } from "../contexts/ConversationsProvider";
import { timeDifference } from "utils/func";

import {
  Input,
  Form,
  Button,
  Badge,
  Label,
  FormGroup,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  Col,
} from "reactstrap";

import { DOMAIN } from "utils";
import avatar from "../../assets/img/default-avatar.png";

import { useContacts } from "chat/contexts/ContactsProvider";
import { SEND_READ_STATUS } from "chat/utils";
import { API_TIMEOUT } from "chat/utils";
import axios from "axios";
import swal from "sweetalert";
import { DELETE_MESSAGE } from "chat/utils";
import successImg from "../../assets/img/success.png";
import { KIRIM_SURVEY } from "utils";

export default function OpenConversation({ id, konsultasiId, statusProses }) {
  const [text, setText] = useState("");
  const setRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ smooth: true });
    }
  }, []);
  const { sendMessage, selectedConversation } = useConversations();
  const [msgRender, setMsgRender] = useState(selectedConversation.messages);

  useEffect(() => {
    if (selectedConversation) {
      setMsgRender(selectedConversation.messages);
    }
  }, [selectedConversation]);

  const { contacts } = useContacts();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);

  const [Q1, setQ1] = useState("");
  const [Q2, setQ2] = useState("");
  const [Q3, setQ3] = useState("");
  const [Q4, setQ4] = useState("");
  const [Q5, setQ5] = useState("");
  const [Q6, setQ6] = useState("");
  const [Q7, setQ7] = useState("");
  const [Q8, setQ8] = useState("");
  const [Q9, setQ9] = useState("");

  const [unreadmsg, setunreadmsg] = useState("");

  function countUnreadMessages(messages) {
    let unreadCount = 0;
    let unreadMessages = [];

    messages.forEach((message) => {
      if (!message.readStatus && message.msgId) {
        unreadCount++;
        unreadMessages.push({ messageid: message.msgId, userid: id });
      }
    });

    return {
      unreadCount: unreadCount,
      unreadMessages: unreadMessages,
    };
  }

  useEffect(() => {
    setunreadmsg(countUnreadMessages(selectedConversation.messages));
  }, [selectedConversation]);

  useEffect(() => {
    if (unreadmsg && unreadmsg.unreadCount > 0) {
      sendReadStatus();
    }
  }, [unreadmsg]);

  const sendReadStatus = () => {
    axios({
      method: "post",
      url: SEND_READ_STATUS,
      timeout: API_TIMEOUT,
      data: unreadmsg.unreadMessages,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  function handleSubmit(e) {
    e.preventDefault();

    sendMessage(
      selectedConversation.recipients.map((r) => r.id),
      text,
      selectedConversation.conversationId
    );
    setText("");
  }

  function findContactProfileById(id) {
    const contact = contacts.find((contact) => contact.id === id);
    return contact ? contact.profile : undefined;
  }

  const deleteMsg = (msgid) => {
    const data = msgRender;
    const msgFil = data.filter((item) => {
      return item.msgId != msgid;
    });
    setMsgRender(msgFil);
  };

  const deleteMessage = (msgid) => {
    deleteMsg(msgid);

    axios({
      method: "delete",
      url: DELETE_MESSAGE + msgid,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleKirimSurvey = () => {
    if ((Q1, Q2, Q3, Q4, Q5, Q6, Q7, Q8, Q9)) {
      kirimSurvey();
    } else {
      swal(
        "Info",
        "Masih ada pertanyaan yang belum dijawab. kembali mengisi survey",
        "info"
      );
    }
  };

  const kirimSurvey = () => {
    axios({
      method: "post",
      url: KIRIM_SURVEY,
      timeout: API_TIMEOUT,
      data: {
        Q1: Q1,
        Q2: Q2,
        Q3: Q3,
        Q4: Q4,
        Q5: Q5,
        Q6: Q6,
        Q7: Q7,
        Q8: Q8,
        Q9: Q9,
        konsultasiId: konsultasiId,
        tanggalStatus: "2024-04-09",
        idAdmin: id,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          swal(
            "Success",
            "Terima Kasih sudah membantu kami dengan mengisi survey",
            "success"
          );
          setModalOpen3(false);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  return (
    <div className="d-flex flex-column flex-grow-1">
      {selectedConversation.recipients.length > 1 ? (
        <span style={{ cursor: "pointer" }} onClick={() => setModalOpen(true)}>
          {" "}
          {selectedConversation.nama}{" "}
          <Badge color="success">
            {selectedConversation.recipients.length}
          </Badge>{" "}
          anggota
        </span>
      ) : (
        <span style={{ color: "blue" }}>
          {selectedConversation.recipients.map((r) => r.name).join(", ")}
        </span>
      )}
      <div className="flex-grow-1 overflow-auto">
        <div className="d-flex flex-column align-items-start justify-content-end px-3">
          {msgRender.map((message, index) => {
            const lastMessage = msgRender.length - 1 === index;
            return (
              <div
                ref={lastMessage ? setRef : null}
                key={index}
                className={`my-1 d-flex flex-column ${
                  message.fromMe
                    ? "align-self-end align-items-end"
                    : "align-items-start"
                }`}
              >
                <div
                  className={`rounded px-2 py-1 ${
                    message.fromMe ? "bg-primary text-white" : "border"
                  }`}
                  style={{ position: "relative" }}
                >
                  {message.text}
                  {message.fromMe && (
                    <div
                      style={{
                        width: 15,
                        height: 15,
                        borderRadius: 8,
                        backgroundColor: "GrayText",
                        position: "absolute",
                        right: -7,
                        top: -7,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteMessage(message.msgId)}
                    >
                      <span style={{ fontSize: 10 }}>x</span>
                    </div>
                  )}
                </div>
                <div
                  className={`text-muted small ${
                    message.fromMe ? "text-right" : ""
                  }`}
                  style={{ marginTop: 5 }}
                >
                  <img
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      marginRight: 10,
                    }}
                    src={
                      findContactProfileById(message.sender) != "-"
                        ? DOMAIN + findContactProfileById(message.sender)
                        : avatar
                    }
                  />
                  {message.fromMe ? "You" : message.senderName}
                  <span style={{ marginLeft: 5, marginRight: 5 }}>at</span>
                  <span style={{ color: "green", fontSize: 10 }}>
                    {timeDifference(message.createdAt)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Input
            type="textarea"
            required
            value={text}
            onChange={(e) => setText(e.target.value)}
            style={{
              height: "75px",
              resize: "none",
              color: "#808080",
              padding: 10,
            }}
          />

          <Button
            type="submit"
            color="warning"
            style={{ height: "75px", resize: "none" }}
          >
            Send
          </Button>
          {statusProses != "Selesai" && (
            <div
              style={{
                width: 80,
                height: 40,
                position: "relative",
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
              onClick={() => {
                setModalOpen2(true);
              }}
            >
              <img
                src={successImg}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                }}
              />
            </div>
          )}
        </div>
      </Form>
      <Modal
        size="sm"
        isOpen={modalOpen}
        toggle={() => setModalOpen(false)}
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader toggle={() => setModalOpen(false)}>
          <Label>Anggota Group</Label>
        </ModalHeader>
        <ModalBody>
          {selectedConversation.recipients.map((contact) => (
            <div>
              <FormGroup check key={contact.id}>
                <img
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    marginRight: 10,
                  }}
                  src={
                    findContactProfileById(contact.id) != "-"
                      ? DOMAIN + findContactProfileById(contact.id)
                      : avatar
                  }
                />
                <Label>
                  <span>
                    <span /> {contact.name}
                  </span>
                </Label>
              </FormGroup>
            </div>
          ))}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              className="btn-rounded"
              size="sm"
              color="warning"
              onClick={() => setModalOpen(false)}
            >
              Ok
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        size="sm"
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(false)}
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader toggle={() => setModalOpen2(false)}></ModalHeader>
        <ModalBody>
          <Label>Apakah Konsultasi sudah dianggap selesai?</Label>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              className="btn-rounded"
              size="sm"
              color="warning"
              onClick={() => setModalOpen2(false)}
            >
              Tidak
            </Button>
            <div style={{ width: 20 }}></div>
            <Button
              className="btn-rounded"
              size="sm"
              color="warning"
              onClick={() => {
                setModalOpen2(false);
                setModalOpen3(true);
              }}
            >
              Ya
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        size="md"
        isOpen={modalOpen3}
        toggle={() => setModalOpen3(false)}
        contentClassName="p-0"
        modalClassName="modal-slide-in event-sidebar"
      >
        <ModalHeader toggle={() => setModalOpen3(false)}>
          SURVEY KEPUASAN LAYANAN
        </ModalHeader>
        <ModalBody>
          <Label>
            <p>
              Bantu kami meningkatkan pelayanan dengan menjawab pertanyaan di
              bawah
            </p>
          </Label>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              1. Bagaimana Pendapat Saudara tentang kesesuaian persyaratan
              pelayanan dengan jenis pelayanannya
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q1}
                  onChange={(event) => {
                    setQ1(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Sesuai"}</option>
                  <option value={"2"}>{"Kurang Sesuai."}</option>
                  <option value={"3"}>{"Sesuai"}</option>
                  <option value={"4"}>{"Sangat Sesuai"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              2. Bagaimana pemahaman saudara tentang kemudahan prosedur
              pelayanan di unit ini.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q2}
                  onChange={(event) => {
                    setQ2(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Mudah"}</option>
                  <option value={"2"}>{"Kurang Mudah"}</option>
                  <option value={"3"}>{"Mudah"}</option>
                  <option value={"4"}>{"Sangat Mudah"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              3. Bagaimana pendapat Saudara tentang kecepatan waktu dalam
              memberikan pelayanan
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q3}
                  onChange={(event) => {
                    setQ3(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Cepat"}</option>
                  <option value={"2"}>{"Kurang Cepat."}</option>
                  <option value={"3"}>{"Cepat"}</option>
                  <option value={"4"}>{"Sangat Cepat"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              4. Bagaimana pendapat Saudara tentang kewajaran biaya/ tarif dalam
              pelayanan
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q4}
                  onChange={(event) => {
                    setQ4(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Sangat Mahal"}</option>
                  <option value={"2"}>{"Mahal."}</option>
                  <option value={"3"}>{"Kurang Mahal"}</option>
                  <option value={"4"}>{"Tidak Mahal/Gratis"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              5. Bagaimana pendapat Saudara tentang kesesuaian produk pelayanan
              antara yang tercantum dalam standar pelayanan dengan hasil yang
              diberikan.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q5}
                  onChange={(event) => {
                    setQ5(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Sesuai"}</option>
                  <option value={"2"}>{"Kurang Sesuai"}</option>
                  <option value={"3"}>{"Sesuai"}</option>
                  <option value={"4"}>{"Sangat Sesuai"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              6. Bagaimana pendapat Saudara tentang kompetensi/ kemampuan
              petugas dalam pelayanan.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q6}
                  onChange={(event) => {
                    setQ6(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Kompeten"}</option>
                  <option value={"2"}>{"Kurang Kompeten"}</option>
                  <option value={"3"}>{"Kompeten"}</option>
                  <option value={"4"}>{"Sangat Kompeten"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              7. Bagaimana pendapat Saudara tentang perilaku petugas dalam
              pelayanan terkait kesopanan dan keramahan.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q7}
                  onChange={(event) => {
                    setQ7(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak Sopan dan Ramah"}</option>
                  <option value={"2"}>{"Kurang Sopan dan Ramah"}</option>
                  <option value={"3"}>{"Sopan dan Ramah"}</option>
                  <option value={"4"}>{"Sangat Sopan dan Ramah"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              8. Bagaimana pendapat Saudara tentang kualitas sarana dan
              prasarana.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q8}
                  onChange={(event) => {
                    setQ8(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak ada"}</option>
                  <option value={"2"}>{"Ada tetapi tidak diterapkan"}</option>
                  <option value={"3"}>
                    {"Diterapkan tetapi kurang maksimal"}
                  </option>
                  <option value={"4"}>{"Diterapkan sepenuhnya"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label lg="8" className="text-md-left mt-3">
              9. Bagaimana pendapat Saudara tentang penanganan pengaduan
              pengguna layanan.
            </Label>
            <Col lg="4">
              <div>
                <label></label>
                <Input
                  style={{ color: "black" }}
                  type="select"
                  name="liga"
                  value={Q9}
                  onChange={(event) => {
                    setQ9(event.target.value);
                  }}
                >
                  <option value={""}>- pilih -</option>
                  <option value={"1"}>{"Tidak ada"}</option>
                  <option value={"2"}>{"Ada tetapi tidak berfungsi"}</option>
                  <option value={"3"}>{"Berfungsi kurang maksimal"}</option>
                  <option value={"4"}>{"Dikelola dengan baik"}</option>
                </Input>
              </div>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              className="btn-rounded"
              size="sm"
              color="warning"
              onClick={() => setModalOpen3(false)}
            >
              Batal
            </Button>
            <div style={{ width: 20 }}></div>
            <Button
              className="btn-rounded"
              size="sm"
              color="warning"
              onClick={() => {
                handleKirimSurvey();
              }}
            >
              Kirim
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

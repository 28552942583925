import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Progress,
} from "reactstrap";

import axios from "axios";
import ReactMde from "react-mde";
import { useState, useEffect } from "react";
import {
  PERANGKAT_DAERAH,
  API_TIMEOUT,
  ANALISIS_JABATAN,
  POST_ANJAB,
} from "utils";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { GET_ANJAB } from "utils";
import { formatDate } from "utils/func";
import { toProperCase } from "utils/func";
import Success from "../assets/img/success.png";
import { downloadFile } from "utils/func";
import { logApp } from "utils/func";
import { refreshToken } from "utils/func";
import { GET_PENGADUANALL } from "utils";
import { GET_TRACKPENGADUAN } from "utils";
import { formatDateTime } from "utils/func";
import { POST_TRACKPENGADUAN } from "utils";
import { domain } from "utils";

import progres from "../assets/img/progres.png";

const CONTACTS_KEY = "contacts";
function Survey() {
  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [dokumen, setDokumen] = useState("");
  const history = useHistory();

  const [id_unit, setId_Unit] = useState("");
  const [urlFile1, setUrlFile1] = useState("");
  const [file, setFile] = useState("");
  const [status, setStatus] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [catatan, setCatatan] = useState("");
  const [latest_status, setLatest_status] = useState("");
  const [progressAnjab, setProgressAnjab] = useState([]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [accessToken, setAccessToken] = useState("");

  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);

  const [conversId, setConversId] = useState("");
  const [konsultasiId, setKonsultasiId] = useState("");
  const [konsulData, setKonsulData] = useState({
    id: "",
    nama: "",
    kategori: "",
  });
  const [statusProses, setStatusProses] = useState("");

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Pengaduan");
      } else {
        history.push("/login");
      }
    }
    // refreshToken(history, setAccessToken);
  }, []);

  useEffect(() => {
    if (currentUser) {
      ambilPengaduan();
    }
  }, [currentUser]);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };

  const ambilPengaduan = (id) => {
    axios({
      method: "post",
      url: GET_PENGADUANALL,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          setTabelPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilAnjab = (id) => {
    axios({
      method: "get",
      url: GET_TRACKPENGADUAN + id,
      timeout: API_TIMEOUT,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setProgressAnjab(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const handleSubmit = async (event) => {
    if (id_unit && status && tanggal && catatan) {
      const formData = new FormData();
      formData.append("photo", dokumen);

      formData.append("idPengaduan", id_unit);
      formData.append("statusProses", status);
      formData.append("tanggalStatus", tanggal);

      formData.append("catatan", catatan);
      formData.append("idAdmin", currentUser.uid);

      await axios({
        method: "post",
        url: POST_TRACKPENGADUAN,
        timeout: API_TIMEOUT,
        data: formData,
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response.status !== 200) {
            // ERROR
            swal("Failed", "ada kesalahan pada server", "error");
          } else {
            //BERHASIL
            swal("Success", "Berita berhasil diposting", "success");
            ambilPengaduan();
            ambilAnjab(id_unit);
            onModalClose();
          }
        })
        .catch((error) => {
          // ERROR
          swal("Failed", "Ada kesalahan pada server", "error");
        });

      setSaveLoading(false);
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
      setSaveLoading(false);
    }
  };

  const onModalClose = () => {
    setStatus("");
    setDokumen("");
    setKonsultasiId("");
    setKonsulData({
      id: "",
      nama: "",
      kategori: "",
    });
    setTanggal("");
    setCatatan("");
    setModalOpen2(false);
  };

  const handleSimpan = () => {
    setSaveLoading(true);
    handleSubmit();
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CardTitle tag="h4">
                  Hasil Survey Kepuasan Layanan Konsultasi
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Kategori</th>

                      <th>Perangkat Daerah</th>

                      <th className="text-center">Status</th>
                      <th>Kepuasan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelPerangkatDaerah &&
                      tabelPerangkatDaerah.map((item, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{toProperCase(item.nama)}</td>
                          <td>{item.kategori}</td>
                          <td>{item.namaPd}</td>

                          <td className="text-center">
                            {
                              <div
                                style={{
                                  width: 80,
                                  height: 40,
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={
                                    item.statusProses
                                      ? item.statusProses == "Selesai"
                                        ? Success
                                        : progres
                                      : progres
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                  }}
                                />
                              </div>
                            }
                          </td>
                          <td className="text-center" style={{ width: 150 }}>
                            <div className={`d-flex justify-content-between`}>
                              <div className="d-flex flex-column mt-3">
                                <div>
                                  <p className="body-2">
                                    {Math.round((item.surveyAverage / 5) * 100)}
                                    % terpenuhi{" "}
                                  </p>
                                </div>
                                <Progress
                                  color={
                                    (item.surveyAverage / 5) * 100 > 50
                                      ? "success"
                                      : "danger"
                                  }
                                  className="progress-xs"
                                  value={(item.surveyAverage / 5) * 100}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          size="lg"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
          color="black"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Tindak Lanjut Pengaduan"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div style={{ backgroundColor: "#333" }}>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Tanggal</th>
                    <th>Status Proses</th>

                    <th>Catatan</th>
                    <th>Dokumen</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {progressAnjab.map((item) => (
                    <tr>
                      <td>{formatDateTime(item.tanggalStatus)}</td>
                      <td>{item.statusProses}</td>
                      <td>{item.catatan}</td>
                      <td>
                        <div>
                          <img
                            src={domain + item.dokumen}
                            alt="Displayed Image"
                            style={{
                              maxWidth: "100px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                              maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                              width: "auto",
                              height: "auto",
                              border: "1px solid #ddd", // Menambahkan border untuk estetika
                              borderRadius: "4px", // Menambahkan radius pada sudut gambar
                              padding: "5px", // Menambahkan padding
                              display: "block", // Memastikan gambar ditampilkan sebagai blok
                              margin: "20px auto", // Menambahkan margin untuk pusat gambar
                            }}
                          />
                        </div>
                      </td>

                      <td className="text-center">
                        <Button
                          color={
                            item.status === "Finish"
                              ? "warning"
                              : item.status === "Final"
                              ? "success"
                              : "primary"
                          }
                          size="sm"
                          onClick={() => {
                            downloadFile(domain + item.dokumen);
                          }}
                        >
                          download
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  setModalOpen2(true);
                }}
              >
                Proses
              </Button>
              <div style={{ margin: 5 }}></div>
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  setProgressAnjab([]);

                  setModalOpen(false);
                  setId_Unit("");
                  setLatest_status("");
                }}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Survey;

/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// core components

import swal from "sweetalert";

import { API_TIMEOUT } from "utils";
import { EFEKTIFITAS } from "utils";
import axios from "axios";
import { USERS } from "utils";
import { toProperCase } from "utils/func";
import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { GET_NOTIF } from "utils";
import { LOGIN_USER } from "actions/AuthAction";
import { formatDate } from "utils/func";
import { GET_AKTIVITY } from "utils";
import { formatDateTime } from "utils/func";
import { logoutUser } from "actions/AuthAction";
import { ADMINS_TOKEN } from "utils";
import jwt_decode from "jwt-decode";
import { POST_PENGADUAN } from "utils";
import { GET_PERANGKATDAERAH } from "utils";
import { GET_DESA } from "utils";
import { GET_PENGADUAN } from "utils";
import { GET_TRACKPENGADUAN } from "utils";
import { domain } from "utils";
import { GET_STATISTIKPENGADUAN } from "utils";
import { GET_PENGADUANALL } from "utils";
import { HASIL_SURVEY } from "utils";
import Detail from "../assets/img/detail.png";

function Dashboard(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");

  const [tabelPerangkatDaerah, setTabelPerangkatDaerah] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [perangkatDaerah, setPerangkatDaerah] = useState([]);
  const [listDesa, setListDesa] = useState([]);
  const [label, setLabel] = useState([]);
  const [dataGraph1, setDataGraph1] = useState({ label: [], dataGraph: [] });
  const [dataGraph2, setDataGraph2] = useState({ label: [], dataGraph: [] });
  const [dataGraph3, setDataGraph3] = useState({ label: [], dataGraph: [] });
  const [penggunaManek, setPenggunaManek] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();
  const [notif, setNotif] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const [expire, setExpire] = useState("");

  const [jenisAdu, setJenisAdu] = useState("");
  const [nama, setNama] = useState("");
  const [alamat, setAlamat] = useState("");
  const [tujuan, setTujaun] = useState("");
  const [status, setStatus] = useState("");
  const [detail, setDetail] = useState("");
  const [uid, setUid] = useState("");
  const [pd, setPd] = useState("");
  const [desa, setDesa] = useState("");
  const [tanggalKetemu, setTanggalKetemu] = useState("");
  const [dokumen, setDokumen] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };
  const onModalClose = () => {
    setJenisAdu("");
    setNama("");
    setAlamat("");
    setTujaun("");
    setStatus("");
    setDetail("");
    setUid("");
    setPd(3);
    setDesa(3);
    setTanggalKetemu("");
    setDokumen("");
    setSaveLoading(false);
    setModalOpen(false);
  };
  const handleSimpan = () => {
    if (
      currentUser &&
      tanggalKetemu &&
      nama &&
      alamat &&
      detail &&
      pd &&
      desa &&
      jenisAdu &&
      desa
    ) {
      setSaveLoading(true);
      handleSubmit();
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("photo", dokumen);

    formData.append("uid", currentUser.uid);
    formData.append("status", "registrasi");
    formData.append("rencanaKetemu", tanggalKetemu);
    formData.append("nama", nama);

    formData.append("alamat", alamat);
    formData.append("jenisAdu", jenisAdu);
    formData.append("detail", detail);
    formData.append("pd", pd);
    formData.append("desa", desa);
    formData.append("tujuan", tujuan);

    await axios({
      method: "post",
      url: POST_PENGADUAN,
      timeout: API_TIMEOUT,
      data: formData,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL
          swal("Success", "Registrasi Pengaduan berhasil diposting", "success");
          ambilPengaduan();
          onModalClose();
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });

    setSaveLoading(false);
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "Dashboard");
      } else {
        history.push("/login");
      }
    }
    //  refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ADMINS_TOKEN, {
        credentials: "include",
      });
      const decoded = jwt_decode(response.data.accessToken);

      setAccessToken(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      logoutUser(history);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ADMINS_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (currentUser) {
      ambilListDesa();
      ambilPerangkatDaerah();
      ambilPengaduan();
      ambilStatistikPengaduan();
      ambilHasilSurvey();
    }
  }, [currentUser]);

  const ambilStatistikPengaduan = (id) => {
    axios({
      method: "get",
      url: GET_STATISTIKPENGADUAN,
      timeout: API_TIMEOUT,
      data: { uid: currentUser.uid },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.kategori;
          });
          const dataGr = data.map((item) => {
            return item.count;
          });

          const dataG = { label: label, dataGraph: dataGr };

          setDataGraph2(dataG);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilHasilSurvey = () => {
    axios({
      method: "get",
      url: HASIL_SURVEY,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          const label = data.map((item) => {
            return item.singk;
          });
          const dataGr = data.map((item) => {
            return item.Q;
          });

          const dataG = { label: label, dataGraph: dataGr };

          setDataGraph1(dataG);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPengaduan = (id) => {
    axios({
      method: "post",
      url: GET_PENGADUANALL,
      timeout: API_TIMEOUT,
      data: { uid: currentUser.uid },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setNotif(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilTrackPengaduan = (id) => {
    axios({
      method: "get",
      url: GET_TRACKPENGADUAN + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setPenggunaManek(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: GET_PERANGKATDAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const ambilListDesa = (id) => {
    axios({
      method: "get",
      url: GET_DESA,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListDesa(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph1.label,
        datasets: [
          {
            label: "Konsultasi",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph1.dataGraph,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph2.label,
        datasets: [
          {
            label: "Survey Kepuasan",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph,
          },
        ],
      };
    },

    options: chart1_2_options,
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12">
            <Card className="card-chart">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="6">
                    <h5 className="card-category">
                      Distribusi Konsultasi Berdasarkan Kategori
                    </h5>
                    <CardTitle tag="h2">Konsultasi Pengawasan</CardTitle>
                  </Col>
                  <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1",
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Survey
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data2",
                        })}
                        onClick={() => setBgChartData("data2")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Konsultasi
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-gift-2" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={chartExample1[bigChartData]}
                    options={chart1_2_options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">Pengaduan </h6>
                <p className="card-category d-inline"> terakhir</p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {notif.map((item) => (
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="text-muted">
                              {item.nama + " - " + item.alamat}
                            </p>
                            <p className="title">
                              {item.statusProses
                                ? item.statusProses
                                : item.status}
                            </p>
                            {item.model == "Online" ? (
                              "Online"
                            ) : (
                              <p className="text-muted">
                                {"Jadwal Ketemu" +
                                  " - " +
                                  formatDate(item.rencanaKetemu) +
                                  " Pukul 08:00 - 09:30"}
                              </p>
                            )}

                            <p>{item.kategori}</p>
                            <p className="text-muted">{item.detail}</p>
                            <div>
                              {item.gambar != "-" && (
                                <img
                                  src={domain + item.gambar}
                                  alt="Displayed Image"
                                  style={{
                                    maxWidth: "300px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                                    maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                                    width: "auto",
                                    height: "auto",
                                    border: "1px solid #ddd", // Menambahkan border untuk estetika
                                    borderRadius: "4px", // Menambahkan radius pada sudut gambar
                                    padding: "5px", // Menambahkan padding
                                    display: "block", // Memastikan gambar ditampilkan sebagai blok
                                    margin: "20px auto", // Menambahkan margin untuk pusat gambar
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td className="td-actions text-right">
                            <div
                              style={{
                                width: 80,
                                height: 40,
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                ambilTrackPengaduan(item.id);
                              }}
                            >
                              <img
                                src={Detail}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Detail Aktifitas Konsultasi</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Waktu</th>
                      <th>Status Proses</th>

                      <th className="text-center">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penggunaManek &&
                      penggunaManek.map((item) => (
                        <tr>
                          <td>{formatDateTime(item.tanggalStatus)}</td>
                          <td>{item.statusProses}</td>

                          <td>{item.catatan}</td>
                          {item.dokumen != "-" && (
                            <td>
                              <div>
                                <img
                                  src={domain + item.dokumen}
                                  alt="Displayed Image"
                                  style={{
                                    maxWidth: "100px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                                    maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                                    width: "auto",
                                    height: "auto",
                                    border: "1px solid #ddd", // Menambahkan border untuk estetika
                                    borderRadius: "4px", // Menambahkan radius pada sudut gambar
                                    padding: "5px", // Menambahkan padding
                                    display: "block", // Memastikan gambar ditampilkan sebagai blok
                                    margin: "20px auto", // Menambahkan margin untuk pusat gambar
                                  }}
                                />
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;

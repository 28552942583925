export const API_CHAT = "https://kokolak.rotendaokab.go.id/api/chat/";
// 'http://localhost:5000/api/';
//'http://localhost:4000/api/';
//'https://manek.rotendaokab.go.id/api/';

export const GET_CONTACT = API_CHAT + "contact/";
export const CONVERSATION = API_CHAT + "conversation/";
export const SEND_MESSAGE = API_CHAT + "send-message/";
export const SEND_READ_STATUS = API_CHAT + "save-readstatus/";
export const DELETE_MESSAGE = API_CHAT + "delete-message/";

export const API_TIMEOUT = 120000;

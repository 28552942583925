import React, { useContext, useState, useEffect, useCallback } from "react";

import { useContacts } from "./ContactsProvider";

import { API_TIMEOUT, CONVERSATION, SEND_MESSAGE } from "../utils";
import axios from "axios";
import swal from "sweetalert";
import { data } from "jquery";

const ConversationsContext = React.createContext();

export function useConversations() {
  return useContext(ConversationsContext);
}

export function ConversationsProvider({ id, children, convId }) {
  // mengambil conversation pada local storage
  //diganti dengan mengambil ke database......
  const [conversations, setConversations] = useState([]);
  const [selectedConversationIndex, setSelectedConversationIndex] = useState(0);
  const { contacts } = useContacts();

  //logik pengganti Socekt IO
  let pollingInterval;
  let timeout;

  function startPolling() {
    if (pollingInterval) return;

    pollingInterval = setInterval(() => {
      getPercakapan();
    }, 5000);
  }

  function stopPolling() {
    clearInterval(pollingInterval);

    pollingInterval = null;
  }

  function onInactivity() {
    stopPolling();
  }

  function onActivity() {
    clearTimeout(timeout);
    if (!document.hidden) {
      // Cek jika tab sedang aktif
      startPolling();

      timeout = setTimeout(onInactivity, 300000); // 5 menit tanpa aktivitas
    }
  }

  function handleVisibilityChange() {
    if (document.hidden) {
      stopPolling();
    } else {
      onActivity();
    }
  }

  useEffect(() => {
    startPolling();
    document.addEventListener("mousemove", onActivity);
    document.addEventListener("keydown", onActivity);
    document.addEventListener("click", onActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange); // Listener untuk perubahan visibilitas tab

    return () => {
      stopPolling();
      document.removeEventListener("mousemove", onActivity);
      document.removeEventListener("keydown", onActivity);
      document.removeEventListener("click", onActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Hapus listener saat komponen di-unmount
    };
  }, []);
  //end logik

  // ini untuk menambhakan conversation atau konversation baruuuuu
  function createConversation(recipients, namaGroup) {
    createPercakapan(recipients, namaGroup);
  }

  const getPercakapan = () => {
    axios({
      method: "post",
      url: CONVERSATION + "user",
      timeout: API_TIMEOUT,
      data: { userId: id, conversationId: convId },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          setConversations(response.data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const cekPercakapan = (recipients) => {
    let sama = false;

    conversations.map((conversation) => {
      if (arrayEquality(conversation.recipients, recipients)) {
        sama = true;
      }
    });

    return sama;
  };

  const createPercakapan = (recipients, namaGroup) => {
    const cek = cekPercakapan(recipients);

    if (cek) {
      return swal(
        "Info",
        "Percakapan yang ingin ditambahkan sudah ada sebelumnya",
        "info"
      );
    }

    axios({
      method: "post",
      url: CONVERSATION,
      timeout: API_TIMEOUT,
      data: { name: namaGroup, userIds: [...recipients, id] },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
          getPercakapan();
          return swal("Success", "Percakapan berhasil ditambahkan", "success");
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const kirimPesan = (id, text, conversationId) => {
    axios({
      method: "post",
      url: SEND_MESSAGE,
      timeout: API_TIMEOUT,
      data: { conversationId: conversationId, text: text, id: id },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const addMessageToConversation = useCallback(
    ({ recipients, text, sender }) => {
      setConversations((prevConversations) => {
        let madeChange = false;
        const newMessage = { sender, text, createdAt: new Date() };
        const newConversations = prevConversations.map((conversation) => {
          if (arrayEquality(conversation.recipients, recipients)) {
            madeChange = true;
            return {
              ...conversation,
              messages: [...conversation.messages, newMessage],
            };
          }

          return conversation;
        });

        if (madeChange) {
          return newConversations;
        } else {
          return [...prevConversations, { recipients, messages: [newMessage] }];
        }
      });
    },
    [setConversations]
  );

  // useEffect(() => {
  //   if (socket == null) return;

  //   socket.on('receive-message', addMessageToConversation);

  //   return () => socket.off('receive-message');
  // }, [socket, addMessageToConversation]);

  function sendMessage(recipients, text, conversationId) {
    //socket.emit('send-message', { recipients, text, conversationId });
    kirimPesan(id, text, conversationId);
    addMessageToConversation({ recipients, text, sender: id });
  }

  const formattedConversations = conversations.map((conversation, index) => {
    const recipients = conversation.recipients.map((recipient) => {
      const contact = contacts.find((contact) => {
        return contact.id === recipient;
      });
      const name = (contact && contact.name) || recipient;
      return { id: recipient, name };
    });

    const messages = conversation.messages.map((message) => {
      const contact = contacts.find((contact) => {
        return contact.id === message.sender;
      });

      const name = (contact && contact.name) || message.sender;
      const fromMe = id === message.sender;
      return { ...message, senderName: name, fromMe };
    });

    const selected = index === selectedConversationIndex;
    return { ...conversation, messages, recipients, selected };
  });

  const value = {
    conversations: formattedConversations,
    selectedConversation: formattedConversations[selectedConversationIndex],
    sendMessage,
    selectConversationIndex: setSelectedConversationIndex,
    createConversation,
  };

  return (
    <ConversationsContext.Provider value={value}>
      {children}
    </ConversationsContext.Provider>
  );
}

function arrayEquality(a, b) {
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  // jika semua anggota sama

  return a.every((element, index) => {
    return element === b[index];
  });
}

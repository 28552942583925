/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// core components

import swal from "sweetalert";

import { API_TIMEOUT } from "utils";
import { EFEKTIFITAS } from "utils";
import axios from "axios";
import { USERS } from "utils";
import { toProperCase } from "utils/func";
import { useHistory } from "react-router-dom";
import { logApp } from "utils/func";
import { GET_NOTIF } from "utils";
import { LOGIN_USER } from "actions/AuthAction";
import { formatDate } from "utils/func";
import { GET_AKTIVITY } from "utils";
import { formatDateTime } from "utils/func";
import { logoutUser } from "actions/AuthAction";
import { ADMINS_TOKEN } from "utils";
import jwt_decode from "jwt-decode";
import { POST_PENGADUAN } from "utils";
import { GET_PERANGKATDAERAH } from "utils";
import { GET_DESA } from "utils";
import { GET_PENGADUAN } from "utils";
import { GET_TRACKPENGADUAN } from "utils";
import { domain } from "utils";
import { CHECK_KAMIS } from "utils";
import { GET_JENISADUAN } from "utils";
import { GET_MODELKONSUL } from "utils";
import messageImg from "../assets/img/message.png";
import Success from "../assets/img/success.png";
import App from "chat/components/App";

function DashboardUser(props) {
  const [bigChartData, setbigChartData] = React.useState("data1");

  const [modalOpen, setModalOpen] = useState(false);
  const [perangkatDaerah, setPerangkatDaerah] = useState([]);
  const [listDesa, setListDesa] = useState([]);
  const [label, setLabel] = useState([]);
  const [dataGraph1, setDataGraph1] = useState({ label: [], dataGraph: [] });
  const [dataGraph2, setDataGraph2] = useState({ label: [], dataGraph: [] });
  const [dataGraph3, setDataGraph3] = useState({ label: [], dataGraph: [] });
  const [penggunaManek, setPenggunaManek] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const history = useHistory();
  const [notif, setNotif] = useState([]);
  const [accessToken, setAccessToken] = useState("");

  const [expire, setExpire] = useState("");

  const [jenisAdu, setJenisAdu] = useState("");
  const [nama, setNama] = useState("");
  const [alamat, setAlamat] = useState("");
  const [tujuan, setTujaun] = useState("");
  const [status, setStatus] = useState("");
  const [detail, setDetail] = useState("");
  const [uid, setUid] = useState("");
  const [pd, setPd] = useState("");
  const [desa, setDesa] = useState("");
  const [tanggalKetemu, setTanggalKetemu] = useState("");
  const [dokumen, setDokumen] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [kamisBerikut, setKamisBerikut] = useState([]);
  const [listJenisAduan, setListJenisAduan] = useState([]);
  const [modelKonsul, setModelKonsul] = useState("");
  const [listModelKonsul, setListModelKonsul] = useState([]);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [conversationId, setCOnversationId] = useState("");
  const [konsultasiId, setKonsultasiId] = useState("");
  const [statusProses, setStatusProses] = useState("");

  const onChangeDok = (e) => {
    setDokumen(e.target.files[0]);
  };
  const onModalClose = () => {
    setModelKonsul("");
    setJenisAdu("");
    setNama("");
    setAlamat("");
    setTujaun("");
    setStatus("");
    setDetail("");
    setUid("");
    setPd("");
    setDesa("");
    setTanggalKetemu("");
    setDokumen("");
    setSaveLoading(false);
    setModalOpen(false);
  };
  const handleSimpan = () => {
    if (currentUser && nama && detail && pd && jenisAdu && modelKonsul) {
      setSaveLoading(true);
      if (modelKonsul == 1 && !tanggalKetemu) {
        swal(
          "Failed",
          "Model Konsultasi yang anda pilih adalah tatap muka untuk itu perlu mengisi tanggal konsultasi",
          "error"
        );
      } else {
        handleSubmit();
      }
    } else {
      swal("Failed", "Ada data yang belum diinput", "error");
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("photo", dokumen);

    formData.append("uid", currentUser.uid);
    formData.append("status", "registrasi");
    formData.append("rencanaKetemu", tanggalKetemu);
    formData.append("nama", nama);
    formData.append("modelKonsul", modelKonsul);
    formData.append("alamat", alamat);
    formData.append("jenisAdu", jenisAdu);
    formData.append("detail", detail);
    formData.append("pd", pd);
    formData.append("desa", desa);
    formData.append("tujuan", tujuan);

    await axios({
      method: "post",
      url: POST_PENGADUAN,
      timeout: API_TIMEOUT,
      data: formData,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          swal("Failed", "ada kesalahan pada server", "error");
        } else {
          //BERHASIL
          swal("Success", "Registrasi Pengaduan berhasil diposting", "success");
          ambilPengaduan();
          onModalClose();
        }
      })
      .catch((error) => {
        // ERROR
        swal("Failed", "Ada kesalahan pada server", "error");
      });

    setSaveLoading(false);
  };

  useEffect(() => {
    // Jika data yang disimpan di localStorage adalah dalam format JSON, Anda perlu menguraikannya menjadi objek
    if (!currentUser) {
      let user = localStorage.getItem("user");
      if (user) {
        user = JSON.parse(user);
        setCurrentUser(user);
        logApp(user.uid, "DashboardUser");

        setKamisBerikut(getNextThursdays());
      } else {
        history.push("/login");
      }
    }
    //  refreshToken();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(ADMINS_TOKEN, {
        credentials: "include",
      });
      const decoded = jwt_decode(response.data.accessToken);

      setAccessToken(response.data.accessToken);

      setExpire(decoded.exp);
    } catch (error) {
      logoutUser(history);
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(ADMINS_TOKEN);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;

        const decoded = jwt_decode(response.data.accessToken);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (currentUser) {
      ambilListDesa();
      ambilPerangkatDaerah();
      ambilPengaduan();
      ambilListJenisAduan();
      ambilListModelKonsul();
    }
  }, [currentUser]);

  useEffect(() => {
    if (tanggalKetemu) {
      checkTanggalKetemu(tanggalKetemu);
    }
  }, [tanggalKetemu]);

  const checkTanggalKetemu = (tanggalKetemu) => {
    axios({
      method: "post",
      url: CHECK_KAMIS,
      timeout: API_TIMEOUT,
      data: { rencanaKetemu: tanggalKetemu },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;
          if (data.length > 5) {
            setTanggalKetemu("");
            swal(
              "Failed",
              "Untuk tanggal yang anda pilih sudah penuh (5 orang sudah memilih sebelumnya). Pilih tanggal lainya",
              "error"
            );
          } else {
            const n = data.length + 1;
            swal(
              "Info",
              "Anda orang ke " +
                n +
                " yang memilih tanggal ini. Lanjutkan registrasi",
              "info"
            );
          }
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPengaduan = (id) => {
    axios({
      method: "post",
      url: GET_PENGADUAN,
      timeout: API_TIMEOUT,
      data: { uid: currentUser.uid },
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setNotif(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilTrackPengaduan = (id) => {
    axios({
      method: "get",
      url: GET_TRACKPENGADUAN + id,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setPenggunaManek(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilPerangkatDaerah = (id) => {
    axios({
      method: "get",
      url: GET_PERANGKATDAERAH,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setPerangkatDaerah(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };
  const ambilListDesa = (id) => {
    axios({
      method: "get",
      url: GET_DESA,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListDesa(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilListJenisAduan = () => {
    axios({
      method: "get",
      url: GET_JENISADUAN,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListJenisAduan(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const ambilListModelKonsul = () => {
    axios({
      method: "get",
      url: GET_MODELKONSUL,
      timeout: API_TIMEOUT,
    })
      .then((response) => {
        if (response.status !== 200) {
          // ERROR
          return "error";
        } else {
          //BERHASIL

          const data = response.data.data;

          setListModelKonsul(data);
        }
      })
      .catch((error) => {
        // ERROR

        alert(error);
      });
  };

  const setBgChartData = (name) => {
    setbigChartData(name);
  };

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a",
          },
        },
      ],
    },
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph1.label,
        datasets: [
          {
            label: "Efektifitas Top 10",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph1.dataGraph,
          },
        ],
      };
    },
    data2: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph2.label,
        datasets: [
          {
            label: "Efektifitas Bottom 10",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph2.dataGraph,
          },
        ],
      };
    },
    data3: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: dataGraph3.label,
        datasets: [
          {
            label: "Efektifitas Random",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataGraph3.dataGraph,
          },
        ],
      };
    },
    options: chart1_2_options,
  };

  // Fungsi untuk memformat tanggal ke dalam format "Kamis, DD Month YYYY"
  function formatLabel(date) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "2-digit",
    };
    const formatter = new Intl.DateTimeFormat("id-ID", options);
    return formatter.format(date);
  }

  // Fungsi untuk mendapatkan tanggal setiap hari Kamis selama 4 minggu ke depan
  function getNextThursdays() {
    const result = [];
    const today = new Date();

    // Get the current day of the week (0-6, where 0 is Sunday)
    const dayOfWeek = today.getDay();

    // Calculate how many days until the next Thursday
    const daysUntilThursday = (4 - dayOfWeek + 7) % 7;

    // If today is Thursday, set the first Thursday as today, else calculate the next Thursday
    let nextThursday = new Date(today);
    if (daysUntilThursday === 0) {
      nextThursday.setDate(today.getDate());
    } else {
      nextThursday.setDate(today.getDate() + daysUntilThursday);
    }

    // Generate the next 4 Thursdays
    for (let i = 0; i < 4; i++) {
      const value = nextThursday.toISOString().split("T")[0]; // Format YYYY-MM-DD
      const label = formatLabel(nextThursday);

      result.push({ value, label });
      nextThursday.setDate(nextThursday.getDate() + 7);
    }

    return result;
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col
            xs="12"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              onClick={() => {
                setNama(currentUser.nama);
                setPd(currentUser.idLokasi);
                setModalOpen(true);
              }}
            >
              Registrasi
            </Button>
          </Col>
        </Row>

        <Row>
          <Col lg="6" md="12">
            <Card className="card-tasks">
              <CardHeader>
                <h6 className="title d-inline">Konsultasi </h6>
                <p className="card-category d-inline"> Inspektorat</p>
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="tim-icons icon-settings-gear-63" />
                  </DropdownToggle>
                  <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Another action
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      Something else
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <Table>
                    <tbody>
                      {notif.map((item) => (
                        <tr>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <Input defaultValue="" type="checkbox" />
                                <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                              </Label>
                            </FormGroup>
                          </td>
                          <td>
                            <p className="title">
                              {item.statusProses
                                ? item.statusProses
                                : item.status}
                            </p>
                            {item.modelKonsul == 1 ? (
                              <p className="text-muted">
                                {"Jadwal Ketemu" +
                                  " - " +
                                  formatDate(item.rencanaKetemu) +
                                  " Pukul 08:00 - 09:30"}
                              </p>
                            ) : (
                              <p className="text-muted">
                                {"Model " + item.model}
                              </p>
                            )}

                            <p>{item.kategori}</p>
                            <p className="text-muted">{item.detail}</p>
                            {item.gambar != "-" && (
                              <div>
                                <img
                                  src={domain + item.gambar}
                                  alt="Displayed Image"
                                  style={{
                                    maxWidth: "300px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                                    maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                                    width: "auto",
                                    height: "auto",
                                    border: "1px solid #ddd", // Menambahkan border untuk estetika
                                    borderRadius: "4px", // Menambahkan radius pada sudut gambar
                                    padding: "5px", // Menambahkan padding
                                    display: "block", // Memastikan gambar ditampilkan sebagai blok
                                    margin: "20px auto", // Menambahkan margin untuk pusat gambar
                                  }}
                                />
                              </div>
                            )}
                          </td>
                          <td className="td-actions text-right">
                            {item.conversationId ? (
                              item.statusProses == "Selesai" ? (
                                <div
                                  style={{
                                    width: 80,
                                    height: 40,
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    ambilTrackPengaduan(item.id);
                                    setCOnversationId(item.conversationId);
                                    setKonsultasiId(item.id);
                                    setStatusProses(item.statusProses);
                                    setModalOpen2(true);
                                  }}
                                >
                                  <img
                                    src={Success}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: 80,
                                    height: 40,
                                    position: "relative",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setCOnversationId(item.conversationId);
                                    setKonsultasiId(item.id);
                                    setStatusProses(item.statusProses);
                                    setModalOpen2(true);
                                  }}
                                >
                                  <img
                                    src={messageImg}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain", // Menyebabkan gambar menyesuaikan dengan ukuran div tanpa terdistorsi
                                    }}
                                  />
                                </div>
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Detail Aktifitas Konsultasi</CardTitle>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Waktu</th>
                      <th>Status Proses</th>

                      <th className="text-center">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {penggunaManek &&
                      penggunaManek.map((item) => (
                        <tr>
                          <td>{formatDateTime(item.tanggalStatus)}</td>
                          <td>{item.statusProses}</td>

                          <td>{item.catatan}</td>
                          <td>
                            {item.dokumen != "-" && (
                              <div>
                                <img
                                  src={domain + item.dokumen}
                                  alt="Displayed Image"
                                  style={{
                                    maxWidth: "100px", // Ubah sesuai dengan lebar maksimum yang diinginkan
                                    maxHeight: "100px", // Ubah sesuai dengan tinggi maksimum yang diinginkan
                                    width: "auto",
                                    height: "auto",
                                    border: "1px solid #ddd", // Menambahkan border untuk estetika
                                    borderRadius: "4px", // Menambahkan radius pada sudut gambar
                                    padding: "5px", // Menambahkan padding
                                    display: "block", // Memastikan gambar ditampilkan sebagai blok
                                    margin: "20px auto", // Menambahkan margin untuk pusat gambar
                                  }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          size="lg"
          isOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen(false)}>
            <div>
              <div className="headline-2">{"Registrasi Konsultasi"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Nama
              </Label>
              <Col md={9}>
                <Input
                  value={nama}
                  onChange={(e) => setNama(e.target.value)}
                  style={{ color: "black" }}
                  type="text"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Nomor Handphone
              </Label>
              <Col md={9}>
                <Input
                  value={alamat}
                  onChange={(e) => setAlamat(e.target.value)}
                  style={{ color: "black" }}
                  type="number"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                Perangkat Daerah/Institusi
              </Label>
              <Col lg="9">
                <div>
                  <label></label>
                  <Input
                    style={{ color: "black" }}
                    type="select"
                    name="liga"
                    value={pd}
                    onChange={(event) => {
                      setPd(event.target.value);
                    }}
                  >
                    <option value={""}>- pilih -</option>
                    {perangkatDaerah.map((item, index) => (
                      <option value={item.id}>{item.nama}</option>
                    ))}
                  </Input>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                Model Konsultasi
              </Label>
              <Col lg="9">
                <div>
                  <label></label>
                  <Input
                    style={{ color: "black" }}
                    type="select"
                    name="liga"
                    value={modelKonsul}
                    onChange={(event) => {
                      setModelKonsul(event.target.value);
                    }}
                  >
                    <option value={""}>- pilih -</option>
                    {listModelKonsul.map((item) => (
                      <option value={item.id}>{item.model}</option>
                    ))}
                  </Input>
                </div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                Jenis Konsultasi
              </Label>
              <Col lg="9">
                <div>
                  <label></label>
                  <Input
                    style={{ color: "black" }}
                    type="select"
                    name="liga"
                    value={jenisAdu}
                    onChange={(event) => {
                      setJenisAdu(event.target.value);
                    }}
                  >
                    <option value={""}>- pilih -</option>
                    {listJenisAduan.map((item) => (
                      <option value={item.id}>{item.kategori}</option>
                    ))}
                  </Input>
                </div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label md={3} className="text-md-right" for="markdown-editor">
                Detail Konstultasi
              </Label>
              <Col md={9}>
                <Input
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                  style={{ color: "black" }}
                  type="textarea"
                  rows="4"
                />
              </Col>
            </FormGroup>
            {modelKonsul == 1 && (
              <FormGroup row>
                <Label md={3} className="text-md-right" for="markdown-editor">
                  Tanggal Rencana Tatap Muka
                </Label>
                <Col md={9}>
                  <Input
                    value={tanggalKetemu}
                    onChange={(e) => setTanggalKetemu(e.target.value)}
                    style={{ color: "black" }}
                    type="date"
                  />
                </Col>
              </FormGroup>
            )}

            <FormGroup row>
              <Label lg="3" className="text-md-right mt-3">
                {"Dokumen yang ingin diupload (gambar)"}
              </Label>
              <Col lg="9">
                <div>
                  <Input
                    id="upload"
                    type="file"
                    accept={"image/*"}
                    onChange={onChangeDok}
                    style={{ color: "black" }}
                  />
                  <label
                    id="upload-label"
                    htmlFor="upload"
                    className={`font-weight-light text-muted `}
                  >
                    {dokumen ? dokumen.name : "Pilih file"}
                  </label>
                  <div className="input-group-append">
                    <label
                      htmlFor="upload"
                      className="btn btn-light m-0 rounded-pill px-4"
                    >
                      <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                    </label>
                  </div>
                </div>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center", paddingBottom: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                className="btn-rounded"
                size="sm"
                color="secondary-red"
                onClick={() => {
                  onModalClose();
                  setModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <div style={{ marginLeft: 5 }}></div>
              {saveLoading ? (
                <Button className="btn-rounded" color="secondary" size="sm">
                  {" "}
                  {"Simpan ..."}
                </Button>
              ) : (
                <Button
                  className="btn-rounded"
                  color="primary"
                  size="sm"
                  onClick={handleSimpan}
                >
                  {" "}
                  {"Simpan"}
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={modalOpen2}
          toggle={() => setModalOpen2(false)}
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader toggle={() => setModalOpen2(false)}>
            <div>
              <div className="headline-2">{"Percakapan - Kokolak"}</div>
            </div>
          </ModalHeader>
          <ModalBody>
            <App
              id={currentUser && currentUser.uid}
              conversationId={conversationId}
              konsultasiId={konsultasiId}
              statusProses={statusProses}
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default DashboardUser;
